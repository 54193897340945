import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import { Logo } from "../../images";
const Nav = styled.nav`
  padding: 0 20px;
  min-height: 9vh;
  background: white;
  width: 100%;
  display: flex;
  z-index: 11;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.08);
`

const Menu = styled.ul`
  list-style: none;
  display: flex;

  li:nth-child(2) {
    margin: 0px 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Item = styled.li``;

const LinkA = styled.a`
  color: white;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const NavIcon = styled.button`
  background: none;

  cursor: pointer;
  border: none;
  outline: none;

  @media (min-width: 797px) {
    display: none;
  }
`;

const Line = styled.span`
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: black;
  transition: width 0.4s ease-in-out;

  :nth-child(2) {
    width: ${(props) => (props.open ? "30%" : "70%")};
  }
`;

const Overlay = styled.div`
  z-index: 11;
  position: fixed;
  margin-top: 4rem;
  height: ${(props) => (props.open ? "91vh" : 0)};
  width: 100vw;
  background: #1c2022;
  opacity: 1;
  transition: height 0.3s ease-in-out;
  z-index: 10;
  blur: 20px 20px;

  @media (min-width: 797px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`
  list-style: none;
  position: absolute;
  left: 20%;
  top: 2%;

  li {
    opacity: ${(props) => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 50px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  li:nth-child(2) {
    margin: 50px 0px;
  }
`;

const MobileMenu = () => {
  const [toggle, toggleNav] = useState(false);
  return (
    <>
      <Nav>
          <Link to="/" aria-label="Home">
            <Logo />
          </Link>
        <NavIcon name="Menu" aria-label="Menu" onClick={() => toggleNav(!toggle)}>
          <Line open={toggle} />
          <Line open={toggle} />
          <Line open={toggle} />
        </NavIcon>
      </Nav>
      {toggle && <Overlay open={toggle}>
        <OverlayMenu open={toggle}>
          <Item>
            <LinkA>
              <Link to="/member-benefits" activeStyle={{color: "grey"}}>Membership</Link>
            </LinkA>
          </Item>
          <Item>
            <LinkA>
              <Link to="/member-experience" activeStyle={{color: "grey"}}>Member Experience</Link>
            </LinkA>
          </Item>
          <Item>
            <LinkA>
              <Link to="/bill-of-rights" activeStyle={{color: "grey"}}>Beliefs</Link>
            </LinkA>
          </Item>
          <Item>
            <LinkA>
              <Link to="/about-us" activeStyle={{color: "grey"}}>Our Story & Team</Link>
            </LinkA>
          </Item>
          <Item>
            <LinkA>
              <Link to="/apply-now" activeStyle={{color: "grey"}}>Apply Now</Link>
            </LinkA>
          </Item>
        </OverlayMenu>
      </Overlay>}
    </>
  );
};

export default MobileMenu;
