import React, { useState, useEffect } from "react";
import * as styles from "./navbar.module.css";
import { Link } from "gatsby";
import { Logo } from "../../images";
import MobileMenu from "./MobileMenu";
import {GatsbyImage} from 'gatsby-plugin-image'

const Navbar = () => {

  const [mobileView, setMobileView] = useState(false);

  const WatchViewPort = () => {
    if (typeof document !== `undefined`) {
      if(window.innerWidth <= 796) {
        if(!mobileView) setMobileView(true);
      } else if(window.innerWidth > 796) {
        if(mobileView) setMobileView(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if(document.readyState === "complete") {
        WatchViewPort()
      }
      window.addEventListener('resize', WatchViewPort, { passive: true }); 
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('resize', WatchViewPort, { passive: true });
      }
    }
  }, [mobileView]);

  return (
    <div>
     {mobileView ? (<div className={styles.mobileNav}>
        <MobileMenu />
      </div>) : (<div className={styles.deskNav}>
        <div className={styles.main}>
          <div className={styles.logo}>
            <Link aria-label="Home" alt="Home" to="/">
             <Logo />
            </Link>
          </div>
          <div className={styles.menu}>
            <ul>
              <li>
              {/*activeStyle={{color: black}}*/}
                <Link to="/member-benefits" activeStyle={{color: "#AC8F00"}}>Membership</Link>
              </li>
              <li>
                <Link to="/member-experience" activeStyle={{color: "#AC8F00"}}>Member Experience</Link>
              </li>
              <li>
                <Link to="/bill-of-rights" activeStyle={{color: "#AC8F00"}}>Beliefs</Link>
              </li>
              <li>
                <Link to="/about-us" activeStyle={{color: "#AC8F00"}}>Our Story & Team</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>)}
    </div>
  );
};

export default Navbar;
