import React from "react";

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100pt" height="47pt" viewBox="0 0 425.006 198.267">
    <g enableBackground="new">
    <clipPath id="cp0">
    <path transform="matrix(1,0,0,-1,0,198.267)" d="M 0 198.267 L 425.006 198.267 L 425.006 0 L 0 0 Z "/>
    </clipPath>
    <g clipPath="url(#cp0)">
    <path transform="matrix(1,0,0,-1,54.6981,39.487596)" d="M 0 0 L -7.662 -18.14 C -7.662 -18.14 -8.003 -18.983 -7.048 -18.883 L -.89 -18.883 C -.594 -18.883 -.354 -19.123 -.354 -19.418 L -.348 -31.399 C -.348 -31.399 -.184 -32.852 .834 -31.762 C 1.555 -30.99 8.457 -12.757 8.457 -12.757 C 8.457 -12.757 8.786 -12.045 7.953 -12.032 C 7.086 -12.018 2.259 -12.131 1.62 -12.144 C 1.3 -12.149 1.065 -11.832 1.066 -11.513 L 1.059 -.164 C 1.059 -.164 .63 .817 0 0 M 14.616 -48.882 L 9.923 -37.461 L -10.066 -37.461 L -14.725 -48.882 L -31.344 -48.882 L -4.326 16.112 L 7.653 16.112 L 31.886 -48.882 Z " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,125.0771,88.3694)" d="M 0 0 C -18.089 0 -25.814 11.089 -25.814 31.086 L -25.814 64.718 L -11.091 64.718 L -11.091 29.541 C -11.091 18.179 -6.728 12.725 .181 12.725 C 7.088 12.725 11.452 18.179 11.452 29.541 L 11.452 64.718 L 26.178 64.718 L 26.178 31.086 C 26.178 11.089 18.451 0 0 0 " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,196.5961,35.8313)" d="M 0 0 L 0 -51.448 L -14.725 -51.448 L -14.725 0 L -29.45 0 L -29.45 12.18 L 14.726 12.18 L 14.726 0 Z " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,253.6469,35.285905)" d="M 0 0 C -10.362 0 -17.907 -8.908 -17.907 -20.179 C -17.907 -31.45 -10.362 -40.358 0 -40.358 C 10.362 -40.358 17.907 -31.45 17.907 -20.179 C 17.907 -8.908 10.362 0 0 0 M 0 -53.083 C -18.998 -53.083 -33.542 -38.358 -33.542 -20.179 C -33.542 -2 -18.998 12.726 0 12.726 C 18.998 12.726 33.541 -2 33.541 -20.179 C 33.541 -38.358 18.998 -53.083 0 -53.083 " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,55.9105,145.7771)" d="M 0 0 L 0 12.357 L 30.065 12.357 L 30.065 -13.095 C 23.056 -22.594 12.635 -26.559 1.292 -26.559 C -18.166 -26.559 -33.013 -12.911 -33.013 6.824 C -33.013 25.637 -17.706 40.207 1.2 40.207 C 12.542 40.207 21.764 35.688 29.234 27.021 L 18.906 17.982 C 14.479 23.332 8.577 27.297 1.292 27.297 C -9.405 27.297 -17.151 18.168 -17.151 6.824 C -17.151 -5.071 -10.051 -13.648 1.2 -13.648 C 5.902 -13.648 11.252 -11.62 15.124 -7.931 L 15.124 0 Z " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,104.2856,171.2293)" d="M 0 0 L 0 64.553 L 42.42 64.553 L 42.42 52.195 L 14.94 52.195 L 14.94 39.376 L 42.42 39.376 L 42.42 27.021 L 14.94 27.021 L 14.94 12.357 L 42.42 12.357 L 42.42 0 Z " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,209.2172,171.2293)" d="M 0 0 L -27.297 37.44 L -27.297 0 L -42.238 0 L -42.238 64.553 L -29.052 64.553 L -1.476 26.928 L -1.476 64.553 L 13.462 64.553 L 13.462 0 Z " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,0,198.267)" d="M 246.176 91.59 L 261.116 91.59 L 261.116 27.036995 L 246.176 27.036995 Z " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,309.6332,172.3362)" d="M 0 0 C -18.351 0 -26.189 11.251 -26.189 31.539 L -26.189 65.66 L -11.249 65.66 L -11.249 29.972 C -11.249 18.444 -6.822 12.911 .186 12.911 C 7.194 12.911 11.621 18.444 11.621 29.972 L 11.621 65.66 L 26.559 65.66 L 26.559 31.539 C 26.559 11.251 18.72 0 0 0 " fill="#030404"/>
    <path transform="matrix(1,0,0,-1,376.5316,118.111598)" d="M 0 0 C -3.413 0 -7.009 -1.844 -7.009 -5.533 C -7.009 -10.235 -1.199 -11.342 2.951 -12.817 C 12.08 -15.955 23.239 -20.195 23.239 -34.305 C 23.239 -48.138 9.96 -54.225 -1.845 -54.225 C -11.987 -54.225 -19.919 -50.167 -27.021 -42.974 L -17.337 -33.475 C -12.358 -38.087 -8.577 -41.683 -1.658 -41.683 C 2.858 -41.683 7.377 -39.563 7.377 -34.581 C 7.377 -29.51 1.383 -27.481 -4.427 -25.359 C -13.004 -22.132 -21.949 -18.075 -21.949 -5.626 C -21.949 6.64 -10.974 12.449 -.183 12.449 C 8.298 12.449 14.938 9.684 21.764 4.52 L 13.372 -5.995 C 9.591 -2.858 4.981 0 0 0 " fill="#030404"/>
    </g>
    </g>
    </svg>
    );
}

export default Logo;


